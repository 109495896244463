<template>
  <div class="home-container">
    <el-row style="display: flex; align-items: center; padding: 0 10%">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <img src="../assets/images/s1-logo.png" class="img-logo" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <h1>StudioUno</h1>
        <h3>One studio for all</h3>
        <div class="description">
          <p>
            Where artists and technologists collaborate to bring you interactive
            projects that explore the boundaries and intersection of their
            métier.
          </p>
          <p>
            Unleash your creativity. Mint incredible art that is significant to
            you. Join the thriving collector community. Gain access to exclusive
            experiences and rewards.
          </p>
        </div>
      </el-col>
    </el-row>

    <el-row class="first-row">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <div class="project-description">
            <h2 class="project-title">Norte</h2>
            <p>
              Your web3 identity is here. A radically fresh experience, our
              inaugural project Norte empowers you to create and mint beautiful
              art pieces that project your Ethereum address. All on-chain, by
              <a href="https://www.cryptoarte.io/about" target="_blank"
                >Seb (CryptoArte)</a
              >.
            </p>
          </div>
          <div class="project-button-div">
            <a href="/norte" class="project-button-link">
              <el-button type="success" size="large" :round="true">
                <p class="project-button">Create & Mint &#8594;</p>
              </el-button>
            </a>
          </div>
          <div>
            <p class="project-links">
              <a
                href="https://etherscan.io/address/0x4834e620cff02b44a68d416da8a971a12228228c"
                target="_blank"
                >Smart contract</a
              >
              &bull;
              <a
                href="https://opensea.io/collection/studiouno-norte"
                target="_blank"
                >OpenSea</a
              >
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
        <el-card
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <el-image
            :src="require('../assets/images/norte-classic-prime-preview.png')"
            class="preview-image"
            fit="contain"
            style="width: 100%; height: auto; aspect-ratio: 1"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 0;
                  padding-top: 40%;
                  padding-bottom: 60%;
                  font-size: 20px;
                "
              >
                <el-icon :size="50" style="width: 3em; height: 3em">
                  <full-screen style="width: 5em; height: 5em" />
                </el-icon>
                <br />Loading...
              </div>
            </template>
          </el-image>
        </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
        <el-card
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <el-image
            :src="require('../assets/images/norte-light-preview.png')"
            class="preview-image"
            fit="contain"
            style="width: 100%; height: auto; aspect-ratio: 1"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 0;
                  padding-top: 40%;
                  padding-bottom: 60%;
                  font-size: 20px;
                "
              >
                <el-icon :size="50" style="width: 3em; height: 3em">
                  <full-screen style="width: 5em; height: 5em" />
                </el-icon>
                <br />Loading...
              </div>
            </template>
          </el-image>
        </el-card>
      </el-col>
    </el-row>

    <hr class="golden-divider" />
    <el-row>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <div class="project-description">
            <h2 class="project-title">Palabras</h2>
            <p>
              A collection of interoperable, animated generative art words by
              <a href="https://www.cryptoarte.io/about" target="_blank"
                >Seb (CryptoArte)</a
              >.
            </p>
            <p>
              Together with <a href="#frases">Frases</a>, the project supports
              emerging artists and explores positivity and NFT culture themes.
            </p>
          </div>
          <div class="project-button-div">
            <a href="/palabras" class="project-button-link">
              <el-button type="success" size="large" :round="true">
                <p class="project-button">Mint &#8594;</p>
              </el-button>
            </a>
          </div>
          <div>
            <p class="project-links">
              <a
                href="https://etherscan.io/address/0x94F8b470F94218b25731Acee65D8F9642447b144"
                target="_blank"
                >Smart contract</a
              >
              &bull;
              <a
                href="https://opensea.io/collection/studiouno-palabras"
                target="_blank"
                >OpenSea</a
              >
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
        <el-card
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <el-image
            :src="require('../assets/images/palabras-peace-pencil-preview.png')"
            class="preview-image"
            fit="contain"
            style="width: 100%; height: auto; aspect-ratio: 1"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 0;
                  padding-top: 40%;
                  padding-bottom: 60%;
                  font-size: 20px;
                "
              >
                <el-icon :size="50" style="width: 3em; height: 3em">
                  <full-screen style="width: 5em; height: 5em" />
                </el-icon>
                <br />Loading...
              </div>
            </template>
          </el-image>
        </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
        <el-card
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <el-image
            :src="
              require('../assets/images/palabras-wgmi-triangles-preview.png')
            "
            class="preview-image"
            fit="contain"
            style="width: 100%; height: auto; aspect-ratio: 1"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 0;
                  padding-top: 40%;
                  padding-bottom: 60%;
                  font-size: 20px;
                "
              >
                <el-icon :size="50" style="width: 3em; height: 3em">
                  <full-screen style="width: 5em; height: 5em" />
                </el-icon>
                <br />Loading...
              </div>
            </template>
          </el-image>
        </el-card>
      </el-col>
    </el-row>

    <hr class="golden-divider" />
    <el-row>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <div class="project-description">
            <a name="frases"></a>
            <h2 class="project-title">Frases</h2>
            <p>
              A growing art collection and collaboration by a set of extremely
              talented and diverse <a href="/artists">artists</a> curated by the
              StudioUno team.
            </p>
            <p>
              Each piece has a corresponding phrase chosen by its artist.
              Collecting their matching palabras enables you to mint them.
            </p>
          </div>
          <div class="project-button-div">
            <a href="/frases" class="project-button-link">
              <el-button type="success" size="large" :round="true">
                <p class="project-button">Mint &#8594;</p>
              </el-button>
            </a>
          </div>
          <div>
            <p class="project-links">
              <a
                href="https://etherscan.io/address/0x9C8397961BA8592F3A212ae5489A0A705bC6edf7"
                target="_blank"
                >Smart contract</a
              >
              &bull;
              <a
                href="https://opensea.io/collection/studiouno-frases"
                target="_blank"
                >OpenSea</a
              >
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
        <el-card
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <el-image
            :src="
              require('../assets/images/frases-abstract-artist-preview.jpg')
            "
            class="preview-image"
            fit="contain"
            style="width: 100%; height: auto; aspect-ratio: 1"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 0;
                  padding-top: 40%;
                  padding-bottom: 60%;
                  font-size: 20px;
                "
              >
                <el-icon :size="50" style="width: 3em; height: 3em">
                  <full-screen style="width: 5em; height: 5em" />
                </el-icon>
                <br />Loading...
              </div>
            </template>
          </el-image>
        </el-card>
      </el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
        <el-card
          style="margin: 5% 10% 5% 0%; width: 90% !important"
          :body-style="{ padding: '10px' }"
        >
          <el-image
            :src="require('../assets/images/frases-life-is-love-preview.jpg')"
            class="preview-image"
            fit="contain"
            style="width: 100%; height: auto; aspect-ratio: 1"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 0;
                  padding-top: 40%;
                  padding-bottom: 60%;
                  font-size: 20px;
                "
              >
                <el-icon :size="50" style="width: 3em; height: 3em">
                  <full-screen style="width: 5em; height: 5em" />
                </el-icon>
                <br />Loading...
              </div>
            </template>
          </el-image>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    setup() {
      const openWindow = (href) => {
        window.open(href, '_blank')
      }
      return {
        openWindow,
      }
    },
  }
</script>

<style>
  .project-button-link:link {
    text-decoration: none;
  }
  .project-button-link:visited {
    text-decoration: none;
  }
  .project-button-link:hover {
    text-decoration: none;
  }
  .project-button-link:active {
    text-decoration: none;
  }
  .project-links {
    font-size: 0.8em;
  }

  .first-row {
    margin-top: 20px;
  }
  .s1-table-cell-class {
    vertical-align: top !important;
  }
  .s1-table-cell-class > .cell {
    word-break: break-word;
  }
  .project-title {
    color: #fd9b3b;
  }
  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .s1-table-cell-class > .cell {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
</style>
<style scoped>
  .home-container {
    padding: 0 10px;
  }

  .s1-section-1 {
    vertical-align: top;
  }
  .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
  }
  .right-container {
    padding-bottom: 0px;
  }
  .right-container h1 {
    font-size: 4em;
    margin: 0.5em;
  }
  .right-container h2 {
    font-size: 2em;
  }
  .img-logo {
    max-width: 400px;
    width: 100%;
    padding-right: 3%;
  }
  .description {
    line-height: 1.5em;
  }
  .description p {
    text-align: left;
  }
  .project-description {
    line-height: 1.5em;
    margin: 10% 0 0 10%;
  }
  .project-description p {
    line-height: 1.5em;
    text-align: left;
  }
  .project-description h2 {
    text-align: left;
  }
  .project-button-div {
    margin-top: 40px;
  }
  .project-button {
    font-size: 1.5em;
    padding: 40px;
  }
  .golden-divider {
    margin: 3% 40px 3% 40px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
  }
  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .right-container h1 {
      font-size: 3em;
      margin: 0.4em;
    }
  }
  /* Specific styles for Small Mobile Devices */
  @media screen and (max-width: 375px) {
    .right-container h1 {
      font-size: 2.5em;
      margin: 0.2em;
    }
    .right-container h2 {
      font-size: 1.5em;
    }
  }
</style>
